import { paddingLabel } from '../M611/M611.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { AsFoundResultP625Formula } from './P625.formulas';
import { commonConstants, Placeholder } from 'journeys/portal/Forms/Sections.constants';

const allOptions = [
    { name: Placeholder.Select_Condition, value: '' },
    { name: commonConstants.ON, value: commonConstants.ON },
    { name: commonConstants.OFF, value: commonConstants.OFF },
    { name: commonConstants.NOT_TESTED, value: commonConstants.NOT_TESTED }
];

const optionsWithoutNotTested = [
    { name: Placeholder.Select_Condition, value: '' },
    { name: commonConstants.ON, value: commonConstants.ON },
    { name: commonConstants.OFF, value: commonConstants.OFF }
];

export const optionsListFunction = selectedValue => selectedValue === commonConstants.NOT_TESTED ? optionsWithoutNotTested : allOptions;

export const AsFoundTestTableSectionDataP625 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'Nodata',
                isLabel: true,
                xs: 4,
            },
            {
                key: 'HMIIndicationLabel',
                label: 'HMI Indication',
                xs: 2,
                isLabel: 'true',
                labelWidth: '96%',
                textAlign: 'center',
                fontSize: '9pt',
                fontWeight: '700',
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1020AftHmiindicationOffLabel',
                    label: 'Actual/Simulated Flame Off Condition',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1020AftHmiindicationOff',
                    value: formData.F1020AftHmiindicationOff,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.Select_Condition,
                    options: optionsListFunction(formData.F1021AftHmiindicationOn),
                    labelWidth: '0',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem',
                },
                {
                    key: 'F1022AftFaultSignalOperates',
                    label: 'Fault Signal Operates Correctly',
                    xs: 4,
                    labelWidth: '97%',
                    padding: paddingLabel,
                    isLabel: true,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1022AftFaultSignalOperates',
                    value: formData.F1022AftFaultSignalOperates,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: commonConstants.YES, value: commonConstants.YES },
                    { name: commonConstants.NO, value: commonConstants.NO }],
                    labelWidth: '0',
                    width: '98%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem',
                    borderRight: '0'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F1021AftHmiindicationOnLabel',
                    label: 'Actual/Simulated Flame On Condition',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1021AftHmiindicationOn',
                    value: formData.F1021AftHmiindicationOn,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.Select_Condition,
                    options: optionsListFunction(formData.F1020AftHmiindicationOff),
                    labelWidth: '0',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    marginBottom: '0',
                    height: '1.55rem',
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '4px 5px 0 0',
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundResultP625Formula(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.3rem',
                    borderRight: '0',
                    borderBottom: '0',
                    direction: 'rtl',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
            ]
        },
    ]
});
