import { commonConstants, Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';

export const HMISectionDataP625 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F100HmireadingPriortoTestLabel',
                label: 'HMI Indication Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F100HmireadingPriortoTest',
                value: formData.F100HmireadingPriortoTest,
                isDropdown: true,
                inputTextAlign: 'center',
                placeholder: Placeholder.Select_ON_OFF,
                options: [{ name: Placeholder.Select_ON_OFF, value: '' },
                { name: commonConstants.ON, value: commonConstants.ON },
                { name: commonConstants.OFF, value: commonConstants.OFF }],
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                marginBottom: '15px'
            }]
        }
    ]
});
