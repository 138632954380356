import { checkURVGreaterThanLRV } from 'helpers/Validation';
import { HMISectionCulatedRangeFormula } from '../P78/P78.formulas';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const formulaCondition = (selectedTag, formName, formData) => (selectedTag.DateTested && !signalToTriggerIsErrorCheck(formName, formData));
export const HMISectionDataP711 = (selectedTag, formName, formData) => {
    let TxReadingPriorToTest;
    if (['P6-22', 'P7-22', 'P7-15', 'P7-14', 'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P7-30', 'P6-27', 'P6-31'].includes(formName)) {
        TxReadingPriorToTest = 'TX Reading Prior to Test';
    } else {
        TxReadingPriorToTest = 'Transmitter Reading Prior to Test';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F100HmireadingPriortoTestLabel',
                    label: 'HMI Reading Prior to Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F100HmireadingPriortoTest',
                    value: formData.F100HmireadingPriortoTest,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    borderBottom: '0',
                    textAlign: 'center',
                },
                {
                    key: 'F301TransmitterReadingLabel',
                    label: TxReadingPriorToTest,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F301TransmitterReading',
                    value: formData.F301TransmitterReading,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    borderBottom: '0',
                    textAlign: 'center',
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'F21HmiLrvLabel',
                    label: 'HMI LRV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F21HmiLrv',
                    value: selectedTag.DateTested ? formData?.F21HmiLrv
                        : formData?.F21HmiLrv ?? selectedTag?.TsprHmiLrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
                },
                {
                    key: 'F22HmiUrvLabel',
                    label: 'HMI URV',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F22HmiUrv',
                    value: selectedTag.DateTested ? formData?.F22HmiUrv
                        : formData?.F22HmiUrv ?? selectedTag?.TsprHmiUrv,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
                }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F105HmicalculatedRangeLabel',
                        label: 'HMI Calculated Range',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F105HmicalculatedRange',
                        value: HMISectionCulatedRangeFormula(formData.F21HmiLrv, formData.F22HmiUrv, formData.F115HmiengineeringUnits),
                        xs: 2,
                        isCalculatedNew: true,
                        readOnly: true,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        borderBottom: '0',
                        borderTop: '0',
                        textAlign: 'center',
                        isAutoGrow: true,
                        marginRight: '0',
                    },
                    {
                        key: 'F115HmiengineeringUnitsLabel',
                        label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F115HmiengineeringUnits',
                        value: selectedTag.DateTested ? formData?.F115HmiengineeringUnits
                            : formData?.F115HmiengineeringUnits ?? selectedTag?.TsprHmiEngineeringUnits,
                        isRequired: true,
                        xs: 2,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        borderBottom: '0',
                        borderTop: '0',
                        marginRight: '0',
                        textAlign: 'center',
                        maxLength: '14',
                        isAutoGrow: true,
                        direction: 'rtl',
                    }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F25InstrumentLrvLabel',
                        label: 'Instrument LRV',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F25InstrumentLrv',
                        value: selectedTag.DateTested ? formData?.F25InstrumentLrv
                            : formData?.F25InstrumentLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                        isRequired: true,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        width: '100%',
                        labelWidth: '0%',
                        height: '1.3rem',
                        textAlign: 'center',
                        borderTop: '0',
                        borderBottom: '0',
                        hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                    },
                    {
                        key: 'F26InstrumentUrvLabel',
                        label: 'Instrument URV',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F26InstrumentUrv',
                        value: selectedTag.DateTested ? formData?.F26InstrumentUrv
                            : formData?.F26InstrumentUrv ?? selectedTag?.InstrumentCalibratedUrv,
                        type: 'number',
                        maxLength: '10',
                        xs: 2,
                        isRequired: true,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        height: '1.2rem',
                        textAlign: 'center',
                        borderBottom: '0',
                        hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                    }]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F27InstrumentCalibratedRangeLabel',
                        label: 'Instrument Calibrated Range',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F27InstrumentCalibratedRange',
                        value: HMISectionCulatedRangeFormula(formData.F25InstrumentLrv, formData.F26InstrumentUrv, formData.F28InstrumentEngineeringUnit),
                        xs: 2,
                        readOnly: true,
                        isCalculatedNew: true,
                        inputWidth: '100%',
                        labelWidth: '0%',
                        borderTop: '0',
                        textAlign: 'center',
                        marginBottom: '15px',
                        isAutoGrow: true,
                        marginRight: '0',
                    },
                    {
                        key: 'F28InstrumentEngineeringUnitLabel',
                        label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F28InstrumentEngineeringUnit',
                        value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit
                            : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                        xs: 2,
                        textAlignLabel: 'end',
                        inputWidth: '100%',
                        labelWidth: '0%',
                        borderTop: '0',
                        marginRight: '0',
                        textAlign: 'center',
                        maxLength: '17',
                        isRequired: true,
                        isAutoGrow: true,
                        direction: 'rtl',
                    }]
            }]
    };
};
