import { FieldHasValue } from 'helpers/Validation';
import { commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const AsLeftResultP625Formula = (formData) => {
    if (FieldHasValue(formData.F1023AltHmiindicationOff) && FieldHasValue(formData.F1024AltHmiindicationOn)) {
        if (formData.F1023AltHmiindicationOff === commonConstants.ON || formData.F1024AltHmiindicationOn === commonConstants.OFF) {
            return 'FAIL';
        }
        return 'PASS';
    }
    return '';
};
