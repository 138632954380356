import styled from 'styled-components';

const textCentreAlign = ['P6-10', 'P6-11', 'P7-27', 'M6-7', 'M6-12', 'M6-9', 'M3-1',
    'M6-3', 'M6-4', 'M6-5', 'M6-8', 'M6-10', 'M6-13', 'M6-6', 'M6-11', 'M6-14',
    'M6-15', 'P7-23', 'P7-16', 'P6-13', 'P7-30', 'P7-29', 'P6-14.2', 'P6-23.2',
    'P7-6', 'P7-31', 'P6-15', 'P7-17', 'C1-2', 'P7-19', 'P8-9.1', 'P7-25', 'P7-26',
    'P7-32', 'P7-33', 'P6-20', 'M6-16', 'M7-1', 'P7-20', 'M6-1', 'P6-14',
    'P8-14.1', 'P6-18', 'P7-9', 'P6-7', 'P8-4.1', 'P8-10.1', 'P7-13', 'P6-16', 'P6-13', 'P7-16',
    'P6-26', 'P6-29', 'P6-25'];

export const StyleHMINewDropdown = styled.div`
#F566LocalValvePositionPriortoTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'P7-27') ? '41.7%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'P7-27') ? '41.2%' : props.width};
    }
}


.Dropdown-placeholder {
    width: ${props => textCentreAlign.includes(props.formName) ? '100%' : '93%'};
    text-align: ${props => textCentreAlign.includes(props.formName) ? 'center' : 'left'};

}
.Dropdown-menu {
    text-align: ${props => textCentreAlign.includes(props.formName) ? 'center' : 'left'};
}

`;
export const StyleValveSizeDropdown = styled.div`
#F347HmivalvePositionAfterTest-wrapper > .Dropdown-root > .Dropdown-control{ 
    @media only screen and (max-width: 1136px) {
        height: ${props => (['P6-23.2'].includes(props.formName)) ? '2rem' : props.height};
    }
    @media only screen and (min-width: 1136px) {
        height: ${props => (['P6-23.2'].includes(props.formName)) ? '1.5rem' : props.height};
    }
}
#F343PhysicalValvePositionAfterTest-wrapper .Dropdown-menu {
    text-align: center;
}
#F571LocalValvePositionAfterTest-wrapper .Dropdown-menu {
    text-align: center;
}
#F368PhysicalDamperPositionAfterTest-wrapper .Dropdown-menu {
    text-align: center;
}
#F347HmivalvePositionAfterTest-wrapper .Dropdown-menu {
    text-align: center;
}
#F341ValveFullStrokeLocalPosition-wrapper .Dropdown-menu {
    text-align: center;
}
#F345ValveFullStrokeHmipositionIndication-wrapper .Dropdown-menu {
    text-align: center;
}
#F341ValveFullStrokeLocalPosition-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'C1-2') ? '35.5%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'C1-2') ? '35%' : props.width};
    }
}
#F343PhysicalValvePositionAfterTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'C1-2') ? '35.5%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'C1-2') ? '35%' : props.width};
    }
}
#F345ValveFullStrokeHmipositionIndication-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'C1-2') ? '40.6%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'C1-2') ? '39.9%' : props.width};
    }
}
#F347HmivalvePositionAfterTest-wrapper > .Dropdown-root{ 
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'C1-2') ? '40.6%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'C1-2') ? '39.9%' : props.width};
    }
}
.Dropdown-placeholder {
    width: ${props => (props.formName === 'P6-10' || props.formName === 'C1-2' || props.formName === 'P6-11' || props.formName === 'P7-6'
        || props.formName === 'P7-27' || props.formName === 'P7-17' || props.formName === 'P7-33'
        || props.formName === 'P6-20' || props.formName === 'M6-16'
        || props.formName === 'M7-1' || props.formName === 'P6-13' || props.formName === 'P7-16') ? '100%' : '93%'};
}
#F437PhysicalPositionofContactirPt-wrapper > .Dropdown-root > .Dropdown-control > .Dropdown-placeholder {
    text-align: center;
}
`;
