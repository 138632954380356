import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { Placeholder, commonConstants } from 'journeys/portal/Forms/Sections.constants';

export const TestResultSectionDataP625 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F82HmiReadingAtLabel',
                    label: 'HMI Indication After Test',
                    xs: 4,
                    labelWidth: '100%',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F82HmiReadingAt',
                    value: formData.F82HmiReadingAt,
                    isDropDown: true,
                    inputTextAlign: 'center',
                    placeholder: Placeholder.Select_ON_OFF,
                    width: '100%',
                    options: [{ name: Placeholder.Select_ON_OFF, value: '' },
                    { name: commonConstants.ON, value: commonConstants.ON },
                    { name: commonConstants.OFF, value: commonConstants.OFF }],
                    labelWidth: '0%',
                    xs: 2,
                    fontWeight: '700',
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    labelWidth: '0%',
                    width: '100%',
                    xs: 3,
                }]
        },
    ]
});
