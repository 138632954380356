import { commonConstants } from 'journeys/portal/Forms/Sections.constants';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

const labelPadding = '18px 0 0 0';
export const typeFlameScanner = (formData) => {
    const isReadingDisabled = formData.F1018TypeOfFlameScanner === commonConstants.Ionization_Rod;
    return {
        rows: [
            {
                key: 1,
                fields: [
                    {
                        key: 'F1018TypeOfFlameScannerLabel',
                        label: 'Type of Flame Scanner',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        padding: labelPadding,
                    },
                    {
                        key: 'F1018TypeOfFlameScanner',
                        value: formData.F1018TypeOfFlameScanner,
                        isDropdown: true,
                        isRequired: true,
                        options: [{ name: 'Select Type', value: '' },
                        { name: 'UV; IR; UV/IR', value: 'UV; IR; UV/IR' },
                        { name: commonConstants.Ionization_Rod, value: commonConstants.Ionization_Rod }],
                        xs: 2,
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        marginTop: '15px',
                        placeholder: 'Select Type'
                    },
                    {
                        key: 'F1019PurgeAirPressureReadingLabel',
                        label: 'Purge Air Pressure Reading',
                        xs: 4,
                        isLabel: true,
                        labelWidth,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: labelPadding,
                    },
                    {
                        key: 'F1019PurgeAirPressureReading',
                        value: isReadingDisabled ? '' : formData.F1019PurgeAirPressureReading,
                        maxLength: '20',
                        xs: 2,
                        width: '100%',
                        labelWidth: '0%',
                        textAlignLabel: 'end',
                        height: '1.1rem',
                        textAlign: 'center',
                        marginTop: '15px',
                        isDisabled: isReadingDisabled,
                        readOnly: isReadingDisabled
                    }
                ]
            },
        ]
    };
};
