import { Placeholder, commonConstants } from 'journeys/portal/Forms/Sections.constants';
import { optionsListFunction } from '../../../AsFoundTestTableSection/Data/P625/P625.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { disableAsLeftTestFields } from '../P66/P66.data';
import { AsLeftResultP625Formula } from './P625.formulas';

const labelPadding = '4px 0 0 8px';
export const AsLeftTestTableSectionDataP625 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },

        {
            key: 2,
            fields: [{
                key: 'Nodata',
                isLabel: true,
                xs: 4,
            },
            {
                key: 'HMIIndicationLabel',
                label: 'HMI Indication',
                xs: 2,
                isLabel: 'true',
                labelWidth: '96%',
                textAlign: 'center',
                fontSize: '9pt',
                fontWeight: '700',
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1023AltHmiindicationOffLabel',
                    label: 'Actual/Simulated Flame Off Condition',
                    xs: 4,
                    labelWidth: '100%',
                    padding: labelPadding,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1023AltHmiindicationOff',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F1023AltHmiindicationOff,
                    isDisabled: disableAsLeftTestFields(formData),
                    isRequired: !disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: Placeholder.Select_Condition,
                    options: optionsListFunction(formData.F1024AltHmiindicationOn),
                    labelWidth: '0',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem',
                },
                {
                    key: 'F1025AltFaultSignalOperates',
                    label: 'Fault Signal Operates Correctly',
                    xs: 4,
                    labelWidth: '97%',
                    padding: labelPadding,
                    isLabel: true,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1025AltFaultSignalOperates',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F1025AltFaultSignalOperates,
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: commonConstants.YES, value: commonConstants.YES },
                    { name: commonConstants.NO, value: commonConstants.NO }],
                    labelWidth: '0',
                    width: disableAsLeftTestFields(formData) ? '94.3%' : '98%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem',
                    borderRight: '0'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F1024AltHmiindicationOnLabel',
                    label: 'Actual/Simulated Flame On Condition',
                    xs: 4,
                    labelWidth: '100%',
                    padding: labelPadding,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1024AltHmiindicationOn',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F1024AltHmiindicationOn,
                    isDisabled: disableAsLeftTestFields(formData),
                    isRequired: !disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: Placeholder.Select_Condition,
                    options: optionsListFunction(formData.F1023AltHmiindicationOff),
                    labelWidth: '0',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    marginBottom: '0',
                    height: disableAsLeftTestFields(formData) ? '1.3rem' : '1.55rem'
                },
                {
                    key: 'F81AsLeftResultLabel',
                    label: 'As-Left Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '4px 5px 0 0',
                },
                {
                    key: 'F81AsLeftResult',
                    value: disableAsLeftTestFields(formData) ? '' : AsLeftResultP625Formula(formData),
                    isDisabled: disableAsLeftTestFields(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.3rem',
                    borderRight: '0',
                    borderBottom: '0',
                    direction: 'rtl',
                    marginBottom: '4px',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
            ]
        },
    ]
});
